import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as allocationActions from '../Allocation/actions';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as formatUtils from "../../helpers/formatUtils";
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from '../../helpers/sagaHelper';
import { sleep } from 'helpers/sleep';

function* deleteBankStatementSaga({ idList }) {
	try {
		const response = yield call(
			rest.del,
			endpointsBackend.BANK_STATEMENT_GET_ALL,
			{ idList: idList }
		);
		yield put(actions.bankStatementDeleteSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.bankStatementDeleteError(e));
	}
}

function* getAllBankStatements(url) {
	try {
		const response = yield call(
			rest.get,
			url
		);

		const entries = [];
		for (let key in response.data) {
			entries.push({
				...response.data[key],
				allocatedAmountRatioInfo: formatUtils.formatAmountRatio(response.data[key].allocatedAmountRatio),
				allocatedAmountRatioValue: formatUtils.ratioValue(response.data[key].allocatedAmountRatio),
				allocatedRecordRatioInfo: formatUtils.formatRatio(response.data[key].allocatedRecordRatio),
				allocatedRecordRatioValue: formatUtils.ratioValue(response.data[key].allocatedRecordRatio),
				confirmedRatioInfo: formatUtils.formatRatio(response.data[key].confirmedRatio),
				confirmedRatioValue: formatUtils.ratioValue(response.data[key].confirmedRatio)
			});
		}

		yield put(actions.bankStatementGetSuccess(entries));
	} catch (e) {
		yield put(actions.bankStatementGetError(e));
	}
}

function* getAllBankStatementsSaga_LogRequestOnBackend() {
	yield call(getAllBankStatements, endpointsBackend.BANK_STATEMENT_GET_ALL);
}

function* getAllBankStatementsSaga_DontLogRequestOnBackend() {
	yield call(getAllBankStatements, endpointsBackend.BANK_STATEMENT_REFRESH);
}

function* getBankStatementByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.BANK_STATEMENT_GET_BY_ID.replace("{bankStatementId}", id)
		);

		yield put(actions.bankStatementGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.bankStatementGetError(e));
	}
}

function* receiveBankStatementsSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.BANK_STATEMENT_RECEIVE_ALL
		);

		yield put(actions.bankStatementReceiveSuccess());

		if (response && response.data && response.data.length) {
			for (let j in response.data) {
				const bankStatementIdList = response.data[j].bankStatementIdList;
				if (bankStatementIdList && bankStatementIdList.length) {
					for (let i in bankStatementIdList) {
						yield put(allocationActions.allocationAutoAllocate(bankStatementIdList[i]))
					}
				}
			}
		}

		// 2024.09.03: If sleep() is not called, backend processes the update bank statements request before auto-allocation, 
		// even though they are called in correct order from the frontend as seen from the frontend logs.
		yield call(sleep, 500);

		yield put(actions.bankStatementUpdate());
	} catch (e) {
		yield put(actions.bankStatementReceiveError(e));
	}
}

function* requestBankStatementsSaga({ request }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.BANK_STATEMENT_REQUEST,
			request
		);

		yield put(actions.bankStatementRequestSuccess());
		yield call(getAllBankStatementsSaga_LogRequestOnBackend);
	} catch (e) {
		yield put(actions.bankStatementRequestError(e));
	}
}

function* uploadBankStatementSaga({ bankStatement }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.BANK_STATEMENT_LOAD_FROM_FILE,
			bankStatement
		);

		if (response && response.data && response.data.fileGotLoaded && response.data.rows && response.data.rows.length) {
			for (let i in response.data.rows) {
				yield put(allocationActions.allocationAutoAllocate(response.data.rows[i].bankStatementId))
			}
		}

		// 2024.09.03: If sleep() is not called, backend processes the update bank statements request before auto-allocation, 
		// even though they are called in correct order from the frontend as seen from the frontend logs.
		yield call(sleep, 200);

		yield put(actions.bankStatementUploadSuccess(response.data));

		yield put(actions.bankStatementUpdate());

		// yield call(getAllBankStatementsSaga);
	} catch (e) {
		yield put(actions.bankStatementUploadError(e));
	}
}

function* bankStatementSaga() {
	yield (takeLatest(actionTypes.BANK_STATEMENT_GET_ALL, getAllBankStatementsSaga_LogRequestOnBackend));
	yield (takeLatest(actionTypes.BANK_STATEMENT_GET_BY_ID, getBankStatementByIdSaga));
	yield (takeLatest(actionTypes.BANK_STATEMENT_RECEIVE, receiveBankStatementsSaga));
	yield (takeLatest(actionTypes.BANK_STATEMENT_REFRESH_ALL, getAllBankStatementsSaga_DontLogRequestOnBackend));
	yield (takeLatest(actionTypes.BANK_STATEMENT_REQUEST, requestBankStatementsSaga));
	yield (takeLatest(actionTypes.BANK_STATEMENT_UPDATE, getAllBankStatementsSaga_LogRequestOnBackend));
	yield (takeLatest(actionTypes.BANK_STATEMENT_UPLOAD, uploadBankStatementSaga));
	yield (takeLatest(actionTypes.BANK_STATEMENT_DELETE, deleteBankStatementSaga));
}

export default bankStatementSaga;