import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsSupplierInvoiceCandidate from '../../store/SupplierInvoice/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';

class SupplierInvoiceCandidateEdit extends Component {

	constructor(props) {
		super(props);
		this.state = {
			id: "",
			nameLang0: "",
			nameLang1: "",
			supplierInvoiceCandidates: [],

			changed: false,
			errors: {}
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue;
		if (e.target.type == "checkbox") {
			thisValue = e.target.checked;
		} else {
			thisValue = e.target.value;
		}
		this.setState({
            changed: true,
			[thisObjectName]: thisValue
		});
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing
		let newOrUpdatedSupplierInvoiceCandidate = {
			nameLang0: this.state.nameLang0,
			nameLang1: this.state.nameLang1,

		}
		if (this.state.id) {
			newOrUpdatedSupplierInvoiceCandidate = {
				id: this.state.id,
				...newOrUpdatedSupplierInvoiceCandidate
			}
		}
        this.setState({
            changed: false
        }); // Should be before calling the Redux action to avoid the small delay between scrolling up and showing the alert
		this.props.onCreateSupplierInvoiceCandidate(newOrUpdatedSupplierInvoiceCandidate, this.props.history);
	}

	onDeleteSupplierInvoiceCandidate = () => {
		if (window.confirm("Are you sure you want to delete this SupplierInvoiceCandidate?")) {
			this.props.onDeleteSupplierInvoiceCandidate(this.state.id, this.props.history);
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.onGetSupplierInvoiceCandidateById(id);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.error !== this.props.error) {
			if (this.props.error) {
				this.setState({
					errors: this.props.error
				});
			} else {
				this.setState({
					errors: ""
				})
			}
		}

		if (prevProps.supplierInvoiceCandidates !== this.props.supplierInvoiceCandidates) {
			if (this.props.supplierInvoiceCandidates && this.props.supplierInvoiceCandidates[0]) {

				this.setState({
					id: this.props.supplierInvoiceCandidates[0].id,
					nameLang0: this.props.supplierInvoiceCandidates[0].nameLang0,
					nameLang1: this.props.supplierInvoiceCandidates[0].nameLang1,

				});
			} else {
				this.setState({
					id: "",
					nameLang0: "",
					nameLang1: "",

				});
			}
		}
	}

	render() {
		const pageTitle = (this.state.id || this.props.loading ? "Edit" : "Create") + " SupplierInvoiceCandidate | " + config.AppName;
		const breadcrumbsTitle = "SupplierInvoiceCandidate";
		const breadcrumbsItem = (this.state.id || this.props.loading ? "Edit" : "New") + " SupplierInvoiceCandidate";

		const editForm = (
			<Row>
				<Col lg="12">
					<Card>
						<CardBody>
							<form
								className="outer-repeater"
								onSubmit={this.onSubmit}
							>
								<div data-repeater-list="outer-group" className="outer">
									<div data-repeater-item className="outer">

										{editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}

										{editFormControls.textControl("nameLang0", "Name", this.onChange, this.state.nameLang0)}

									</div>
								</div>
								<Row className="justify-content-end">
									<Col lg="10">
										{editFormControls.saveButton(this.props.saving, this.state.id)}
										<span> </span>
										{editFormControls.deleteButton(this.props.deleting, this.onDeleteSupplierInvoiceCandidate, this.state.id)}
									</Col>
								</Row>
							</form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						{editFormControls.errorAlert(this.props.error)}

                        {editFormControls.saveSuccessAlert(!this.state.changed && this.props.saveSuccess, "SupplierInvoiceCandidate", "Browse supplierInvoiceCandidates", endpointsFrontend.SUPPLIER_INVOICE_CANDIDATE_BROWSE)}

						{editFormControls.formLoadingSpinner(this.props.loading)}

						{!this.props.loading ? editForm : null}

					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ supplierInvoiceCandidate }) => ({
	error: supplierInvoiceCandidate.error,
	deleting: supplierInvoiceCandidate.deleting,
	loading: supplierInvoiceCandidate.loading,
	saveSuccess: supplierInvoiceCandidate.saveSuccess,
	saving: supplierInvoiceCandidate.saving,
	supplierInvoiceCandidates: supplierInvoiceCandidate.supplierInvoiceCandidates
})

const mapDispatchToProps = dispatch => ({
	onGetSupplierInvoiceCandidateById: (id) => dispatch(actionsSupplierInvoiceCandidate.supplierInvoiceCandidateGetById(id)),
	onCreateSupplierInvoiceCandidate: (supplierInvoiceCandidate, history) => dispatch(actionsSupplierInvoiceCandidate.supplierInvoiceCandidateCreate(supplierInvoiceCandidate, history)),
	onDeleteSupplierInvoiceCandidate: (id, history) => dispatch(actionsSupplierInvoiceCandidate.supplierInvoiceCandidateDelete(id, history))
})


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SupplierInvoiceCandidateEdit);
