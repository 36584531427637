import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";

import * as actions from '../../store/SupplierInvoice/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as browseFormControls from "../../helpers/browseFormControls";
import * as columnsSupplierInvoiceCandidate from '../../definitions/columns/supplierInvoiceCandidate';
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as formatUtils from '../../helpers/formatUtils';
// import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as selectRowUtils from '../../helpers/selectRowUtils';

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

import classes from './Pages.module.css';


class SupplierInvoiceCandidateBrowse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            selectedCandidates: [],
            actionsDropdownOpen: false
        }
    }

    componentDidMount() {
        this.props.onGetSupplierInvoiceCandidates();
    }

    handleChange = date => {
        this.setState({
            startDate: date,
        });
    }

    commandButtons = (cell, row, rowIndex, formatExtraData) => {
        // Could not disable or render the buttons differently on the basis of formatExtraData.movingLines, as apparently it does not get updated when needed
        return (
            <React.Fragment>
                <i
                    className="bx bxs-download"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        this.props.onDownloadAttachment(row.id);
                    }}
                />
            </React.Fragment>
        );
    };

    getAndFormatFieldStatus = (row, value) => {
        switch (value) {
            case "customerName":
                return formatUtils.formatMatchingStatus(row.customerNameMatchingStatus);
            case "customerRegNo":
                return formatUtils.formatMatchingStatus(row.customerRegNoMatchingStatus);
            case "customerVATRegNo":
                return formatUtils.formatMatchingStatus(row.customerRegNoMatchingStatus);
            case "supplierName":
                return formatUtils.formatMatchingStatus(row.supplierNameMatchingStatus);
            case "supplierRegNo":
                return formatUtils.formatMatchingStatus(row.supplierRegNoMatchingStatus);
            case "supplierVATRegNo":
                return formatUtils.formatMatchingStatus(row.supplierRegNoMatchingStatus);
            case "invoiceNo":
                return formatUtils.formatInvoiceDuplicationStatus(row.invoiceDuplicationStatus);
            case "dueDate":
                return formatUtils.formatMatchingStatus(row.dueDate ? "OK" : "VALUE_NOT_SPECIFIED");
            case "invoiceCurrencyCode":
                return formatUtils.formatMatchingStatus(row.detectedCurrencyId ? "OK" : "MATCH_NOT_FOUND");
            case "totalInvoiceAmountWithoutVAT":
                return formatUtils.formatMatchingStatus(row.totalInvoiceAmountWithoutVATMatchingStatus);
            case "totalVATAmount":
                return formatUtils.formatMatchingStatus(row.totalVATAmountMatchingStatus);
            case "totalInvoiceAmountWithVAT":
                return formatUtils.formatMatchingStatus(row.totalInvoiceAmountWithVATMatchingStatus);
            case "totalPaidAmount":
                return formatUtils.formatMatchingStatus(row.totalPaidAmountMatchingStatus);
            case "totalDebtAmount":
                return formatUtils.formatMatchingStatus(row.totalPaidAmountMatchingStatus);
            case "totalPayableAmount":
                return formatUtils.formatMatchingStatus(row.totalPayableAmountMatchingStatus);
            default:
                return "";
        }
    }

    convertCandidates = () => {
        if (window.confirm('All selected rows in the "Ready" status will be converted to supplier invoices')) {
            this.props.onConvertCandidates(this.state.selectedCandidates, this.props.history);
        }
    }

    deleteCandidates = () => {
        if (window.confirm('All selected rows will be deleted')) {
            this.props.onDeleteCandidates(this.state.selectedCandidates);
        }
    }

    render() {

        const pageTitle = "Received supplier invoices | " + config.AppName;
        const breadcrumbsTitle = "Supplier invoices";
        const breadcrumbsItem = "Browse received invoices";

        const columns = [
            columnsSupplierInvoiceCandidate.status,
            columnsSupplierInvoiceCandidate.customerName,
            columnsSupplierInvoiceCandidate.supplierName,
            columnsSupplierInvoiceCandidate.invoiceNo,
            columnsSupplierInvoiceCandidate.invoiceDate,
            columnsSupplierInvoiceCandidate.dueDate,
            columnsSupplierInvoiceCandidate.detectedCurrencyCode,
            columnsSupplierInvoiceCandidate.totalInvoiceAmountWithVAT,
            {
                dataField: "commandButtons",
                text: "",
                formatter: (cell, row, rowIndex) => this.commandButtons(cell, row, rowIndex, {})
            }
        ];

        // const rowEvents = {
        //     onClick: (e, row, rowIndex) => {
        //         this.props.history.push(endpointsFrontend.SUPPLIER_INVOICE_CANDIDATE_EDIT.replace(":id", row.id));
        //     }
        // };

        const selectRow = {
            mode: 'checkbox',

            onSelect: (row, isSelect, rowIndex, e) => {
                const selectedRows = selectRowUtils.getSelectedRowOnSelect(this.state.selectedCandidates, row, isSelect);
                this.setState({
                    selectedCandidates: selectedRows
                });
            },

            onSelectAll: (isSelect, rows, e) => {
                const selectedRows = selectRowUtils.getSelectedRowOnSelectAll(this.state.selectedCandidates, rows, isSelect)
                this.setState({
                    selectedCandidates: selectedRows
                });
            }
        };

        const expandRow = {
            renderer: (row) => {

                const columnsToShow = [
                    "customerName",
                    "customerRegNo",
                    "customerVATRegNo",
                    "supplierName",
                    "supplierRegNo",
                    "supplierVATRegNo",
                    "invoiceNo",
                    "invoiceDate",
                    "dueDate",
                    "invoiceCurrencyCode",
                    "totalInvoiceAmountWithoutVAT",
                    "totalVATAmount",
                    "totalInvoiceAmountWithVAT"
                ];

                if (row.totalDebtAmount > 0) {
                    columnsToShow.push("totalDebtAmount");
                    columnsToShow.push("totalPayableAmount");
                } else if (row.alreadyPaidAmount > 0) {
                    columnsToShow.push("alreadyPaidAmount");
                    columnsToShow.push("totalPayableAmount");
                }


                const rows_CandidateBasicData = columnsToShow.map((value, idx) => {
                    const thisColumn = columnsSupplierInvoiceCandidate[value];
                    const thisValue = row[value];
                    const thisColumnValueFormatted =
                        (value === "invoiceDate"
                            || value === "dueDate"
                            || value === "totalInvoiceAmountWithoutVAT"
                            || value === "totalVATAmount"
                            || value === "totalInvoiceAmountWithVAT"
                            || value === "alreadyPaidAmount"
                            || value === "totalDebtAmount"
                            || value === "totalPayableAmount"
                        )
                            ? thisColumn.formatter(thisValue)
                            : thisValue;
                    return (<React.Fragment>
                        <tr key={idx}>
                            <th>{thisColumn.text}</th>
                            <td>{thisColumnValueFormatted} {this.getAndFormatFieldStatus(row, value)}</td>
                        </tr>
                    </React.Fragment>)
                });

                const rows_CandidateBankAccounts = row.supplierBankAccounts.map((bankAccount, idx) => {
                    console.log(bankAccount)
                    return (<React.Fragment>
                        <tr key={idx}>
                            <td>{bankAccount.accountNo} {formatUtils.formatMatchingStatus(bankAccount.matchingStatus)}</td>
                            <td>{bankAccount.swiftBIC}</td>
                            <td>{bankAccount.bankName}</td>
                        </tr>
                    </React.Fragment>)
                });

                return (
                    <table className="table">
                        <tbody style={{ borderColor: "#FFFFFF" }}>
                            <tr>
                                <td style={{ border: "0px transparent" }}>
                                    <table className={classes.SupplierInvoiceCandidateExpandedTable}>
                                        {rows_CandidateBasicData}
                                    </table>
                                </td>
                                <td style={{ border: "0px transparent" }}>
                                    <table className={classes.SupplierInvoiceCandidateExpandedBankAccounts}>
                                        <tr>
                                            <th>Account No.</th>
                                            <th>SWIFT-BIC</th>
                                            <th>Bank name</th>
                                        </tr>
                                        {rows_CandidateBankAccounts}
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                );
            },
            showExpandColumn: true,
            //expandByColumnOnly: true,
            expandColumnRenderer: ({ expanded }) => browseFormControls.expandColumnRenderer(expanded),
            expandHeaderColumnRenderer: ({ isAnyExpands }) => browseFormControls.expandHeaderColumnRenderer(isAnyExpands)
        }

        const table =
            <div className="mt-3">
                <BootstrapTable
                    keyField='id'
                    data={this.props.supplierInvoiceCandidates}
                    columns={columns}
                    pagination={paginationFactory()}
                    // rowEvents={rowEvents}
                    // rowStyle={{ cursor: "pointer" }}
                    filter={filterFactory()}
                    selectRow={selectRow}
                    expandRow={expandRow}
                />
            </div>

        const actionsDropdown =
            <Dropdown
                isOpen={this.state.actionsDropdownOpen}
                toggle={() => this.setState({
                    actionsDropdownOpen: !this.state.actionsDropdownOpen
                })}
            >
                <DropdownToggle
                    color="primary"
                    disabled={this.props.converting || !this.state.selectedCandidates || !this.state.selectedCandidates.length}
                    caret
                >
                    Actions...
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={this.convertCandidates}>Convert to invoices if status is "Ready"</DropdownItem>
                    {/* <DropdownItem onClick={this.updateData1}>Update bank accounts if status is "Data to update"</DropdownItem>
                    <DropdownItem onClick={this.updateData2}>Update data from partial duplicates</DropdownItem>
                    <DropdownItem onClick={this.updateData3}>Delete duplicates</DropdownItem>*/}
                </DropdownMenu>
            </Dropdown>

        return (

            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        {editFormControls.errorAlert(this.props.error)}

                                        {editFormControls.formLoadingSpinner(this.props.loading)}

                                        {!this.props.loading && this.props.supplierInvoiceCandidates ?
                                            table
                                            : null}

                                        <div style={{ display: 'flex' }}>
                                            {actionsDropdown}

                                            &nbsp;

                                            {editFormControls.deleteButton(this.props.deleting, this.deleteCandidates, true, (!this.state.selectedCandidates || !this.state.selectedCandidates.length))}
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );

    }
}

const mapStateToProps = ({ supplierInvoice }) => ({
    converting: supplierInvoice.converting,
    supplierInvoiceCandidates: supplierInvoice.supplierInvoiceCandidates,
    loading: supplierInvoice.loading,
    error: supplierInvoice.error
})

const mapDispatchToProps = dispatch => ({
    onConvertCandidates: (idList, history) => dispatch(actions.supplierInvoiceCandidateConvert(idList, history)),
    onDeleteCandidates: (idList) => dispatch(actions.supplierInvoiceCandidateDelete(idList)),
    onDownloadAttachment: (id) => dispatch(actions.supplierInvoiceCandidateDownloadAttachment(id)),
    onGetSupplierInvoiceCandidates: () => dispatch(actions.supplierInvoiceCandidateGetAll())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SupplierInvoiceCandidateBrowse));
