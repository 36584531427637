import React from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Col, Container, CustomInput, FormGroup, Input, InputGroup, Label, PopoverHeader, PopoverBody, Row, UncontrolledPopover } from "reactstrap";
import classnames from 'classnames';
import CopyToClipboard from "react-copy-to-clipboard";	// Docs: https://www.npmjs.com/package/react-copy-to-clipboard

// import DatePicker from "react-flatpickr";   // Docs: https://reactstrap-date-picker.afialapis.com
// Does not work as expected. Although looks better than Reactstrap's Input type="date" and shows date in "yyyy-mm-dd" in GUI,
// it still shows Sunday as week's first date (does not react to the prop "weekStartsOn") and also does not
// show the today button as claimed in the docs (does not react to the prop "showTodayButton").
// More importantly, the onChange(e) event does not fill the e.target property,  which makes it impossible to use the values in the form.

import * as endpointsFrontend from '../definitions/endpoints/endpoints-frontend';
import * as errorMessageHelper from './errorMessageHelper';
import * as formatUtils from "./formatUtils";
import LexicalEditor from "components/LexicalEditor/LexicalEditor";
// import Jodit from "components/RTF/Jodit";
// import Slate from "components/RTF/Slate"
import * as stringUtils from "./stringUtils";

export const labelColSize = 2;
export const valueColSize = 6;

export const labelClass = "col-form-label col-lg-" + labelColSize;

export const textControlWithoutLabel = (fieldName, onChange, stateField, placeholderText, isInvalid, disabled) => {
    return (
        <Input
            id={fieldName}
            name={fieldName}
            type="text"
            invalid={isInvalid ? isInvalid : false}
            disabled={disabled}
            className="form-control"
            placeholder={placeholderText}
            onChange={onChange}
            value={stateField ? stateField : ""}
        />
    );
}

export const EditFormControlWithLabel = (props) => {
    const popoverId = props.fieldName + "Popover";
    const hintComponent =
        <span>
            &nbsp;
            <font color="light">
                <i
                    id={popoverId}
                    data-target={popoverId}
                    className={"bx bx-info-circle link-secondary"}
                />
            </font>
            <UncontrolledPopover
                placement="right"
                target={popoverId}
                trigger="hover"
            >
                <PopoverBody>
                    {props.hintText}
                </PopoverBody>
            </UncontrolledPopover>
        </span>

    return (
        <FormGroup className="mb-4" row>
            <Label
                htmlFor={props.fieldName}
                className={labelClass}
            >
                {props.fieldTitle}
                {props.hintText && props.hintText.length ? hintComponent : null}
            </Label>
            <Col lg={valueColSize}>
                {props.children}
            </Col>
        </FormGroup>
    );
}

export const textControl = (fieldName, fieldTitle, onChange, stateField, placeholderText, formErrorObject, isInvalid, hintText) => {
    const fieldTitleStartingWithLowercase = fieldTitle.substring(0, 1).toLowerCase() + fieldTitle.substring(1);
    placeholderText = placeholderText ? placeholderText : "Enter " + fieldTitleStartingWithLowercase;
    return (
        <EditFormControlWithLabel
            fieldName={fieldName}
            fieldTitle={fieldTitle}
            hintText={hintText}
        >
            {textControlWithoutLabel(fieldName, onChange, stateField, placeholderText, (formErrorObject && formErrorObject.controls && formErrorObject.controls.includes(fieldName)) || isInvalid)}
        </EditFormControlWithLabel>
    );
}

export const textAreaControl = (fieldName, fieldTitle, onChange, stateField, placeholderText, hintText) => {
    const fieldTitleStartingWithLowercase = fieldTitle.substring(0, 1).toLowerCase() + fieldTitle.substring(1);
    return (
        <EditFormControlWithLabel
            fieldName={fieldName}
            fieldTitle={fieldTitle}
            hintText={hintText}
        >
            <Input
                id={fieldName}
                name={fieldName}
                type="textarea"
                placeholder={placeholderText ? placeholderText : ("Enter " + fieldTitleStartingWithLowercase)}
                onChange={onChange}
                value={stateField ? stateField : ""}
            />
        </EditFormControlWithLabel>
    );
}

export const textAreaControlWithRemovableItems = (fieldName, fieldTitle, onChange, stateFieldLastEntry, stateFieldFullList, stateFieldHoveredItem, removeItemFromStateField, onMouseEnter, onMouseLeave, placeholderText, hintText) => {

    const fieldTitleStartingWithLowercase = fieldTitle.substring(0, 1).toLowerCase() + fieldTitle.substring(1);

    const removableItems = stateFieldFullList && stateFieldFullList.length
        ? (<React.Fragment>
            {stateFieldFullList.split(",").map((thisEntry, key) => (
                removableItem(thisEntry, key,
                    () => removeItemFromStateField(thisEntry),
                    () => onMouseEnter(thisEntry),
                    () => onMouseLeave(),
                    (stateFieldHoveredItem === thisEntry)
                )
            ))}
        </React.Fragment>)
        : null;
    return (
        <EditFormControlWithLabel
            fieldName={fieldName}
            fieldTitle={fieldTitle}
            hintText={hintText}
        >
            {removableItems}
            <Input
                id={fieldName}
                name={fieldName}
                type="textarea"
                placeholder={placeholderText ? placeholderText : ("Enter " + fieldTitleStartingWithLowercase)}
                onChange={onChange}
                value={stateFieldLastEntry ? stateFieldLastEntry : ""}
            />
        </EditFormControlWithLabel>
    );
}

export const richTextControlWithoutLabel = (fieldName, fieldTitle, onChange, stateField, placeholderText) => {
    const fieldTitleStartingWithLowercase = fieldTitle.substring(0, 1).toLowerCase() + fieldTitle.substring(1);
    const text = (stateField ? stateField : '');
    return (
        // <Slate 
        // />
        // <Input
        //     id={fieldName}
        //     name={fieldName}
        //     type="textarea"
        //     placeholder={placeholderText ? placeholderText : ("Enter " + fieldTitleStartingWithLowercase)}
        //     onChange={onChange}
        //     value={text}
        // />
        // <Jodit
        //     contentsAsHTML={text}
        //     onChange={onChange}
        // />
        <LexicalEditor
            contentsAsHTML={text}
            placeholderText={placeholderText ? placeholderText : ("Enter " + fieldTitleStartingWithLowercase)}
            onChange={onChange}
            id={fieldName}
        />
    );
}

export const richTextControl = (fieldName, fieldTitle, onChange, stateField, placeholderText) => {
    return (
        <EditFormControlWithLabel
            fieldName={fieldName}
            fieldTitle={fieldTitle}
        >
            {richTextControlWithoutLabel(fieldName, fieldTitle, onChange, stateField, placeholderText)}
        </EditFormControlWithLabel>
    );
}

export const numberControl = (fieldName, fieldTitle, onChange, stateField, step, placeholderText, disabled, isInvalid, hintText) => {
    const fieldTitleStartingWithLowercase = fieldTitle.substring(0, 1).toLowerCase() + fieldTitle.substring(1);
    return (
        <EditFormControlWithLabel
            fieldName={fieldName}
            fieldTitle={fieldTitle}
            hintText={hintText}
        >
            <Input
                id={fieldName}
                name={fieldName}
                type="number"
                step={step}
                className={classnames("form-control", { "is-invalid": isInvalid })}
                disabled={disabled}
                placeholder={placeholderText ? placeholderText : ("Enter " + fieldTitleStartingWithLowercase)}
                onChange={onChange}
                value={stateField != null ? stateField : "" /* 2024.03.25: It is important for the New Counter form that the value is only rendered as "" if currentValue is null, but not when it is 0. */}
            />
        </EditFormControlWithLabel>
    );
}

export const numberControlWithMinMaxWithoutLabel = (fieldName, fieldTitle, onChange, stateField, step, min, max, disabled) => {
    const fieldTitleStartingWithLowercase = fieldTitle.substring(0, 1).toLowerCase() + fieldTitle.substring(1);
    return (
        <Input
            id={fieldName}
            name={fieldName}
            type="number"
            min={min}
            max={max}
            step={step}
            className="form-control"
            placeholder={(fieldTitle ? "Enter " + fieldTitleStartingWithLowercase : "0")}
            onChange={onChange}
            disabled={disabled}
            value={stateField ? stateField : ""}
        />
    );
}

export const numberControlWithMinMax = (fieldName, fieldTitle, onChange, stateField, step, min, max) => {
    return (
        <EditFormControlWithLabel
            fieldName={fieldName}
            fieldTitle={fieldTitle}
        >
            {numberControlWithMinMaxWithoutLabel(fieldName, fieldTitle, onChange, stateField, step, min, max)}
        </EditFormControlWithLabel>
    );
}

export const timeControlWithoutLabel = (fieldName, onChange, stateField) => {
    const idHours = fieldName + "_hours";
    const idMinutes = fieldName + "_minutes";
    const wholeHours = Math.floor(stateField);
    const minutes = Math.round((stateField - wholeHours) * 60);
    const timeControlChange = (e, onChange) => {
        let newHourValue, newMinuteValue;
        if (e.target.name === idHours) {
            newHourValue = e.target.value;
            newMinuteValue = document.getElementById(idMinutes).value;
        } else if (e.target.name === idMinutes) {
            newHourValue = document.getElementById(idHours).value;
            newMinuteValue = e.target.value;
        } else {
            // unexpected code
        }
        const newE = {
            target: {
                name: fieldName,
                type: "time-control",
                value: parseInt(newHourValue) + (newMinuteValue / 60)
            }
        }
        onChange(newE);
    }
    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col lg={valueColSize / 2}>
                        <Input
                            id={idHours}
                            name={idHours}
                            type="number"
                            min={0}
                            step={1}
                            className="form-control"
                            onChange={(e) => timeControlChange(e, onChange)}
                            value={wholeHours}
                        />
                    </Col>
                    <Col xs="auto">
                        <span style={{ fontSize: "1.5rem" }}>
                            :
                        </span>
                    </Col>
                    <Col lg={valueColSize / 2}>
                        <Input
                            id={idMinutes}
                            name={idMinutes}
                            type="number"
                            min={0}
                            step={1}
                            className="form-control"
                            onChange={(e) => timeControlChange(e, onChange)}
                            value={minutes}
                        />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export const timeControl = (fieldName, fieldTitle, onChange, stateField) => {
    return (
        <EditFormControlWithLabel
            fieldName={fieldName}
            fieldTitle={fieldTitle}
        >
            {timeControlWithoutLabel(fieldName, onChange, stateField)}
        </EditFormControlWithLabel>
    );
}

export const dateControl = (fieldName, fieldTitle, onChange, stateField, disabled, isInvalid) => {
    return (
        <EditFormControlWithLabel
            fieldName={fieldName}
            fieldTitle={fieldTitle}
        >
            <Input
                id={fieldName}
                name={fieldName}
                type="date"
                className={classnames("form-control", { "is-invalid": isInvalid })}
                onChange={onChange}
                value={formatUtils.removeTimeFromDatetime(stateField)}
                disabled={disabled}
            />
            {/*<DatePicker
                    id={fieldName}
                    name={fieldName}
                    dateFormat="YYYY-MM-DD"
                    disabled={disabled}
                    /*weekStartsOn={1}*/          // does not work and causes errors in the browser console that the prop doesn't exist
                    /*showTodayButton={true}*/    // does not work and causes errors in the browser console that the prop doesn't exist
                    /*className="form-control"
                    onChange={onChange}
                    value={stateField}
    />*/}
        </EditFormControlWithLabel>
    );
}

export const checkboxControlWithoutLabel = (fieldName, onChange, stateField, disabled) => {
    return (
        <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
            <Input
                id={fieldName}
                name={fieldName}
                type="checkbox"
                className="form-control"
                onChange={onChange}
                disabled={disabled}
                value={stateField ? stateField : ""}
                checked={stateField ? true : false}
            />
        </div>
    );
}

export const checkboxControl = (fieldName, fieldTitle, onChange, stateField, hintText, disabled) => {
    return (
        <EditFormControlWithLabel
            fieldName={fieldName}
            fieldTitle={fieldTitle}
            hintText={hintText}
        >
            {checkboxControlWithoutLabel(fieldName, onChange, stateField, disabled)}
        </EditFormControlWithLabel>
    );
}

export const miniCheckboxControl = (fieldName, fieldTitle, onChange, stateField) => {
    return (
        <CustomInput
            type="checkbox"
            id={fieldName}
            name={fieldName}
            label={fieldTitle}
            checked={stateField}
            onChange={onChange}
        />
    );
}

export const mediumCheckboxControl = (fieldName, fieldTitle, onChange, stateField) => {
    return (
        <Row className="justify-content-end">
            <Col xs="auto">
                {checkboxControlWithoutLabel(fieldName, onChange, stateField)}
            </Col>
            <Col style={{ paddingLeft: "0px" }}>
                <div style={{ display: "flex", "align-items": "flex-end", height: "100%" }}>
                    {fieldTitle}
                </div>
            </Col>
        </Row>
    )
}

export const selectControlWithoutLabel = (fieldName, onChange, stateField, options, disabled, isInvalid) => {
    return (
        <Input
            id={fieldName}
            name={fieldName}
            type="select"
            className={classnames("form-control", { "is-invalid": isInvalid })}
            onChange={onChange}
            value={stateField ? stateField : ""}
            disabled={disabled}
        >
            {options}
        </Input>
    );
}

export const selectControl = (fieldName, fieldTitle, onChange, stateField, options, disabled, isInvalid) => {
    return (
        <EditFormControlWithLabel
            fieldName={fieldName}
            fieldTitle={fieldTitle}
        >
            {selectControlWithoutLabel(fieldName, onChange, stateField, options, disabled, isInvalid)}
        </EditFormControlWithLabel>
    );
}

export const selectControlWithGoToButton = (fieldName, fieldTitle, onChange, stateField, options, link, history, disabled, isInvalid) => {
    const handleClick = () => {
        history.push(link);
    };

    return (
        <FormGroup className="mb-4" row>
            <Label
                htmlFor={fieldName}
                className={labelClass}
            >
                {fieldTitle}
            </Label>
            <Col lg={valueColSize - 1}>
                {selectControlWithoutLabel(fieldName, onChange, stateField, options, disabled, isInvalid)}
            </Col>
            <Col lg={1}>
                <Button
                    style={{ width: "100%" }}
                    onClick={handleClick}
                >
                    Go
                </Button>
            </Col>
        </FormGroup>
    );
}

export const staticTextControlWithoutLabel = (fieldName, value) => {
    return (
        <Input
            id={fieldName}
            name={fieldName}
            type="text"
            className="form-control-plaintext"
            onChange={() => { }/* needed to avoid warnings in the browser console */}
            value={value ? value : ""}
        />
    )
}

export const staticTextAreaControlWithoutLabel = (fieldName, value) => {
    return (
        <Input
            id={fieldName}
            name={fieldName}
            type="textarea"
            className="form-control-plaintext"
            onChange={() => { }/* needed to avoid warnings in the browser console */}
            value={value ? value : ""}
        />
    )
}

export const staticTextControl = (fieldName, fieldTitle, value) => {
    return (
        <EditFormControlWithLabel
            fieldName={fieldName}
            fieldTitle={fieldTitle}
        >
            {staticTextControlWithoutLabel(fieldName, value)}
        </EditFormControlWithLabel>
    );
}

export const staticTextAreaControl = (fieldName, fieldTitle, value) => {
    return (
        <EditFormControlWithLabel
            fieldName={fieldName}
            fieldTitle={fieldTitle}
        >
            {staticTextAreaControlWithoutLabel(fieldName, value)}
        </EditFormControlWithLabel>
    );
}

export const staticTextControlWithKey = (fieldName, fieldTitle, value, key) => {
    return (
        <EditFormControlWithLabel
            fieldName={fieldName}
            fieldTitle={fieldTitle}
            key={key}
        >
            {staticTextControlWithoutLabel(fieldName, value)}
        </EditFormControlWithLabel>
    );
}

export const hiddenValueControl = (fieldName, onChange, value) => {
    return (
        <Input
            id={fieldName}
            name={fieldName}
            type="text"
            onChange={onChange}
            value={value}
            hidden
        />
    );
}


export const passwordControl = (fieldName, fieldTitle, onChange, stateField, placeholderText, hasPassword, changePassword, setChangePassword) => {
    const fieldTitleStartingWithLowercase = fieldTitle.substring(0, 1).toLowerCase() + fieldTitle.substring(1);
    placeholderText = placeholderText ? placeholderText : "Enter " + fieldTitleStartingWithLowercase;

    const setPasswordControl =
        <Input
            id={fieldName}
            name={fieldName}
            type="password"
            className="form-control"
            placeholder={placeholderText}
            onChange={onChange}
            value={stateField ? stateField : ""}
        />

    const changePasswordControls =
        <InputGroup>
            {setPasswordControl}
            <Button
                color="light"
                onClick={() => setChangePassword(false)}
            >
                Cancel
            </Button>
        </InputGroup>

    const unchangedPasswordControls =
        <InputGroup>
            <Input
                id={"has_" + fieldName}
                name={"has_" + fieldName}
                type="text"
                className="form-control-plaintext"
                onChange={() => { }/* needed to avoid warnings in the browser console */}
                value={"********"}
            />
            <Button
                color="light"
                onClick={() => setChangePassword(true)}
            >
                Change
            </Button>
        </InputGroup>

    return (
        <EditFormControlWithLabel
            fieldName={fieldName}
            fieldTitle={fieldTitle}
        >
            {!hasPassword ? setPasswordControl : (changePassword ? changePasswordControls : unchangedPasswordControls)}
        </EditFormControlWithLabel>
    );
}

export const changeableStaticTextControl = (fieldName, fieldTitle, onChange, stateField, placeholderText, changeValue, setChangeValue) => {
    const fieldTitleStartingWithLowercase = fieldTitle.substring(0, 1).toLowerCase() + fieldTitle.substring(1);
    placeholderText = placeholderText ? placeholderText : "Enter " + fieldTitleStartingWithLowercase;

    const setValueControl =
        <Input
            id={fieldName}
            name={fieldName}
            type="text"
            className="form-control"
            placeholder={placeholderText}
            onChange={onChange}
            value={stateField ? stateField : ""}
        />

    const changeValueControls =
        <InputGroup>
            {setValueControl}
            <Button
                color="light"
                onClick={() => setChangeValue(false)}
            >
                Cancel
            </Button>
        </InputGroup>

    const unchangedValueControls =
        <InputGroup>
            <Input
                id={"has_" + fieldName}
                name={"has_" + fieldName}
                type="text"
                className="form-control-plaintext"
                onChange={() => { }/* needed to avoid warnings in the browser console */}
                value={stateField ? stateField : ""}
            />
            <Button
                color="light"
                onClick={() => setChangeValue(true)}
            >
                Change
            </Button>
        </InputGroup>

    return (
        <EditFormControlWithLabel
            fieldName={fieldName}
            fieldTitle={fieldTitle}
        >
            {(!stateField || !stateField.length) ? setValueControl : (changeValue ? changeValueControls : unchangedValueControls)}
        </EditFormControlWithLabel>
    );
}

export const fileControl = (fieldName, fieldTitle, onChange, filename, hasFile, uploadFile, setUploadFile) => {

    const cancelButton =
        <Button
            color="light"
            onClick={() => setUploadFile(false)}
        >
            Cancel
        </Button>

    const uploadFileControls =
        <InputGroup>
            <Input
                id={fieldName}
                name={fieldName}
                type="file"
                onChange={onChange}
            />
            {" "}
            {hasFile ? cancelButton : null}
        </InputGroup>

    const unchangedFileControls =
        <InputGroup>
            <Input
                id={"has_" + fieldName}
                name={"has_" + fieldName}
                type="text"
                className="form-control-plaintext"
                onChange={() => { }/* needed to avoid warnings in the browser console */}
                value={filename ? filename : ""}
            />
            <Button
                color="light"
                onClick={() => setUploadFile(true)}
            >
                Change
            </Button>
        </InputGroup>

    return (
        <EditFormControlWithLabel
            fieldName={fieldName}
            fieldTitle={fieldTitle}
        >
            {!hasFile || uploadFile ? uploadFileControls : unchangedFileControls}
        </EditFormControlWithLabel>
    );
}

export const buttonSpinner = () => (
    <i className="bx bx-loader bx-spin font-size-18 align-middle" />
)

export const bigSpinner = () => (
    <i className="bx bx-loader bx-spin font-size-24 align-middle" />
)

export const newButton = (saving, onClick, disabled) => (
    <Button
        color="primary"
        type="button"
        disabled={saving || disabled}
        onClick={onClick}
    >
        New
        {" "}
        {saving ? buttonSpinner() : null}
    </Button>
)

export const saveButton = (saving, isUpdateButton) => (
    <Button
        color="primary"
        type="submit"
        disabled={saving}
    >
        {isUpdateButton ? "Update" : "Create"}
        {" "}
        {saving ? buttonSpinner() : null}
    </Button>
)

export const testButton = (testing, onClick, disabled) => (
    <Button
        color="secondary"
        type="button"
        disabled={testing || disabled}
        onClick={onClick}
    >
        Test connection
        {" "}
        {testing ? buttonSpinner() : null}
    </Button>
)

export const copyButton = (copying, onCopy, showButton) => {
    if (showButton) {
        return (
            <Button
                color="primary"
                onClick={onCopy}
                disabled={copying}
            >
                Duplicate
                {" "}
                {copying ? buttonSpinner() : null}
            </Button>
        )
    } else {
        return null;
    }
}

export const deleteButton = (deleting, onDelete, showButton, disabled, customText) => {
    if (showButton) {
        return (
            <Button
                color="danger"
                onClick={onDelete}
                disabled={deleting || disabled}
            >
                {customText ? customText : "Delete"}
                {" "}
                {deleting && buttonSpinner()}
            </Button>
        )
    } else {
        return null;
    }
}

export const copyToClipboardButton = (text) => (
    <CopyToClipboard
        text={text}
    >
        <Button
            color="secondary"
            onClick={() => { window.alert("Data copied to clipboard") }}
        >
            Copy to clipboard
        </Button>
    </CopyToClipboard>
)

export const copyTableToClipboardButton = (data, columns, buttonSize) => {
    const crlf = stringUtils.GetPlatformSpecificCRLF();
    let text = "";
    for (let j in columns) {
        text = text + columns[j].text + (j < columns.length - 1 ? "\t" : crlf);
    }
    for (let i in data) {
        const thisDataRow = data[i];
        for (let j in columns) {
            const dataField = columns[j].dataField;
            let dataFieldValue;
            const includesSubtable = (dataField && dataField.includes && dataField.includes("."));
            if (includesSubtable) {
                const subtable = dataField.split(".")[0];
                const subtableField = dataField.split(".")[1];
                dataFieldValue = thisDataRow[subtable][subtableField];
            } else {
                dataFieldValue = thisDataRow[dataField];
            }
            if (columns[j].formatter) {
                dataFieldValue = columns[j].formatter(dataFieldValue);
            }
            text = text + dataFieldValue + (j < columns.length - 1 ? "\t" : crlf);
        }
    }
    return (
        <CopyToClipboard
            text={text}
        >
            <Button
                color="secondary"
                size={buttonSize ? buttonSize : ""}
                onClick={() => { window.alert("Data copied to clipboard") }}
            >
                Copy to clipboard
            </Button>
        </CopyToClipboard>
    )
}

export const uploadSubform = (file, uploading, handleChange, handleSubmit, additionalControls) => (
    <form
        onSubmit={handleSubmit}
    >
        <input
            type="file"
            onChange={handleChange}
            disabled={uploading}
        >

        </input>
        {" "}
        <Button
            color="primary"
            type="submit"
            disabled={!file || uploading}
        >
            Upload
            {" "}
            {uploading ? buttonSpinner() : null}
        </Button>
        {" "}
        {additionalControls}
    </form>
);

export const formLoadingSpinner = (loading) => {
    if (loading) {
        return (
            <div width="100%" height="100%" align="center">
                <br /><br />
                {bigSpinner()}
            </div>
        );
    } else {
        return null;
    }
}

const errorAlertWithAdditionalInformation = (errorText) => {
    let linkURL = "";
    let linkText = "";
    const regexCustomerId = /\(customerId=\<(\d+)\>\)/g;
    if (errorText.includes('CompanySettings.getAddressCountry()" is null')) {
        errorText = "Error: Company country is not set. ";
        linkURL = endpointsFrontend.COMPANY_SETTINGS_EDIT;
        linkText = "Click here to update company settings."
    } else if (errorText.match(regexCustomerId)) {
        const customerId = errorText.match(regexCustomerId)[0].match(/\d+/)[0];
        errorText = errorText.replace(regexCustomerId, '');
        linkURL = endpointsFrontend.CUSTOMER_EDIT.replace(":id", customerId);
        linkText = "Click here to update the customer."
    }
    if (linkURL.length && linkText.length) {
        return <Alert color="danger">{errorText} <Link to={linkURL}>{linkText}</Link></Alert>
    } else {
        return <Alert color="danger">{errorText}</Alert>
    }
}

export const errorAlert = (error1, error2) => {
    const errorText1 = error1 ? errorMessageHelper.httpErrorMessageAndAdditionalText("Error", error1) : "";
    const errorText2 = error2 ? errorMessageHelper.httpErrorMessageAndAdditionalText("Error", error2) : "";
    if (error1) {
        return errorAlertWithAdditionalInformation(errorText1);
    } else if (error2) {
        return errorAlertWithAdditionalInformation(errorText2);
    } else {
        return null;
    }
}

export const errorAlertWithLink = (error, linkURL, linkText) => {
    const errorText = error ? errorMessageHelper.httpErrorMessageAndAdditionalText("Error", error) : "";
    if (error) {
        return <Alert color="danger">{errorText} <Link to={linkURL}>{linkText}</Link></Alert>
    } else {
        return null;
    }
}

export const warningAlertWithLink = (warningText, linkURL, linkText) => {
    if (warningText && warningText.length && linkURL && linkText) {
        return <Alert color="warning">{warningText} <Link to={linkURL}>{linkText}</Link></Alert>
    } if (warningText && warningText.length) {
        return <Alert color="warning">{warningText}</Alert>
    } else {
        return null;
    }
}

export const copySuccessAlert = (copySuccess, className, pageNameToReturn, pageURLToReturn) => {
    if (copySuccess) {
        if (pageNameToReturn && pageURLToReturn) {
            return <Alert color="success">{className} duplicated successfully. <Link to={pageURLToReturn}>Click to return to {pageNameToReturn}</Link></Alert>
        } else {
            return <Alert color="success">{className} duplicated successfully</Alert>
        }
    } else {
        return null;
    }
}

export const saveSuccessAlert = (saveSuccess, className, pageNameToReturn, pageURLToReturn) => {
    if (saveSuccess) {
        if (pageNameToReturn && pageURLToReturn) {
            return <Alert color="success">{className} saved successfully. <Link to={pageURLToReturn}>Click to return to {pageNameToReturn}</Link></Alert>
        } else {
            return <Alert color="success">{className} saved successfully</Alert>
        }
    } else {
        return null;
    }
}

export const testSuccessAlert = (testSuccess) => {
    if (testSuccess) {
        return <Alert color="success">Connection test succeeded</Alert>
    } else {
        return null;
    }
}

export const radioButton = (fieldName, onChange, stateField, value, caption) =>
    <Col xs="auto">
        <Input
            id={fieldName}
            name={fieldName}
            type="radio"
            onChange={onChange}
            checked={stateField === value}
            value={value}
        />
        &nbsp;
        &nbsp;
        {caption}
    </Col>

export const removableItem = (itemTitle, itemKey, onRemoveItem, onMouseEnter, onMouseLeave, showRemoveItemButton) => {
    const removeItemButton =
        <span
            style={{
                cursor: "pointer",
                marginLeft: "-0.3rem",
                position: "absolute",
                left: "100%",
                top: "-0.4rem"
            }}
            onClick={() => onRemoveItem()}
            className={"badge badge-soft-danger font-size-12"}
        >
            x
        </span>
    return (
        <React.Fragment key={itemKey}>
            <span key={itemKey}>
                <span
                    className={"badge badge-soft-secondary font-size-12"}
                    onMouseEnter={() => onMouseEnter()}
                    onMouseLeave={() => onMouseLeave()}
                    style={{
                        position: "relative",
                        display: "inline-block"
                    }}
                >
                    {itemTitle}
                    {showRemoveItemButton && removeItemButton}
                </span>
                &nbsp;
                {" "}
            </span>
        </React.Fragment>)
}