import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, FormGroup, Label, Row } from "reactstrap";

import * as actionsCompanySettings from "store/CompanySettings/actions";
import Breadcrumbs from "components/Common/Breadcrumb";
import * as config from "../../config";
import * as editFormControls from "helpers/editFormControls";
import { EditFormControlWithLabel } from "helpers/editFormControls";

class CompanySettingsEdit_SupplierInvoiceProcessing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            useSupplierInvoiceReceiving: false,
            supplierInvoiceReceivingHost: "",
            supplierInvoiceReceivingUsername: "",
            supplierInvoiceReceivingPassword: "",
            supplierInvoiceReceivingUseStartTLS: "",
            supplierInvoiceReceivingImapPort: "",
            supplierInvoiceReceivingUseAuth: "",
            supplierInvoiceReceivingSSLProtocols: "TLSv1.2",
            supplierInvoiceReceivingSmtpPort: "",
            supplierInvoiceReceivingForwardTo: "",
            supplierInvoiceReceivingSenderBlacklist: "",
            supplierInvoiceReceivingSenderGreylist: "",
            supplierInvoiceReceivingSenderWhitelist: "",
            supplierInvoiceReceivingIgnoreSendersNotOnWhitelist: false,
            supplierInvoiceReceivingAutoConvert: "",
            supplierInvoiceReceivingAutoConvert_CustomerMatching: "REQUIRE_TWO_FIELDS",
            supplierInvoiceReceivingAutoConvert_SupplierMatching: "REQUIRE_TWO_FIELDS",

            testEmailSettingsBeforeUpdating: true,

            hasSupplierInvoiceReceivingPassword: false,
            changeSupplierInvoiceReceivingPassword: false,

            useForwardTo: true,
            useBlacklist: false,
            useGreylist: false,
            useWhitelist: false,

            blacklistLastEntry: "",
            greylistLastEntry: "",
            whitelistLastEntry: "",

            hoveredBlacklistItem: null,
            hoveredGreylistItem: null,
            hoveredWhitelistItem: null,

            changed: false,
            companySettings: [],
            error: null,
            warningText: "",
            warningLink: "",
            warningLinkText: ""
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            changed: true,
            [thisObjectName]: thisValue
        });
    }

    separateWithCommas = (value1, value2) => {
        if (value1 && value1.length && value2 && value2.length) {
            return value1 + "," + value2;
        } else if (value1 && value1.length) {
            return value1;
        } else {
            return value2;
        }
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        const invalidElements = document.querySelectorAll('.is-invalid');
        if (invalidElements && invalidElements.length) {
            invalidElements[0].focus();
        } else {
            const updatedCompanySettings = {
                useSupplierInvoiceReceiving: this.state.useSupplierInvoiceReceiving,
                supplierInvoiceReceivingHost: this.state.supplierInvoiceReceivingHost,
                supplierInvoiceReceivingUsername: this.state.supplierInvoiceReceivingUsername,
                changeSupplierInvoiceReceivingPassword: this.state.changeSupplierInvoiceReceivingPassword || (this.state.supplierInvoiceReceivingPassword && this.state.supplierInvoiceReceivingPassword.length),
                supplierInvoiceReceivingPassword: this.state.supplierInvoiceReceivingPassword,
                supplierInvoiceReceivingUseStartTLS: this.state.supplierInvoiceReceivingUseStartTLS,
                supplierInvoiceReceivingImapPort: this.state.supplierInvoiceReceivingImapPort,
                supplierInvoiceReceivingUseAuth: this.state.supplierInvoiceReceivingUseAuth,
                supplierInvoiceReceivingSSLProtocols: this.state.supplierInvoiceReceivingSSLProtocols,
                supplierInvoiceReceivingSmtpPort: this.state.supplierInvoiceReceivingSmtpPort,
                supplierInvoiceReceivingForwardTo: this.state.supplierInvoiceReceivingForwardTo,
                supplierInvoiceReceivingSenderBlacklist: this.state.useBlacklist ? this.separateWithCommas(this.state.supplierInvoiceReceivingSenderBlacklist, this.state.blacklistLastEntry) : "",
                supplierInvoiceReceivingSenderGreylist: this.state.useGreylist ? this.separateWithCommas(this.state.supplierInvoiceReceivingSenderGreylist, this.state.greylistLastEntry) : "",
                supplierInvoiceReceivingSenderWhitelist: this.state.useWhitelist ? this.separateWithCommas(this.state.supplierInvoiceReceivingSenderWhitelist, this.state.whitelistLastEntry) : "",
                supplierInvoiceReceivingIgnoreSendersNotOnWhitelist: this.state.supplierInvoiceReceivingIgnoreSendersNotOnWhitelist,
                supplierInvoiceReceivingAutoConvert: this.state.supplierInvoiceReceivingAutoConvert,
                supplierInvoiceReceivingAutoConvert_CustomerMatching: this.state.supplierInvoiceReceivingAutoConvert_CustomerMatching,
                supplierInvoiceReceivingAutoConvert_SupplierMatching: this.state.supplierInvoiceReceivingAutoConvert_SupplierMatching,

                testEmailSettingsBeforeUpdating: this.state.testEmailSettingsBeforeUpdating
            };
            this.setState({
                changed: false
            }); // Should be before calling the Redux action to avoid the small delay between scrolling up and showing the alert
            this.props.onUpdateCompanySettings(updatedCompanySettings);
        }
    }

    componentDidMount() {
        this.props.onGetCompanySettings();
    }

    processCommaSeparatedList = (thisList) => {
        let result = "";
        if (thisList && thisList.length) {
            const listItems = thisList.split(",");
            for (let i in listItems) {
                let thisValue = listItems[i].trim();
                if (thisValue && thisValue.length) {
                    result = (result && result.length) ? result + "," + thisValue : thisValue;
                }
            }
        }
        return result;
    }

    addValueBeforeLastComma = (originalString, addedString) => {
        originalString = this.processCommaSeparatedList(originalString);
        addedString = addedString.trim();
        if (addedString && addedString.length) {
            if (originalString && originalString.length) {
                return (addedString && addedString.length && addedString.includes(",")) ? (originalString + "," + addedString.substring(0, addedString.lastIndexOf(","))) : originalString;
            } else {
                return (addedString && addedString.length && addedString.includes(",")) ? (addedString.substring(0, addedString.lastIndexOf(","))) : "";
            }
        } else {
            return originalString;
        }
    }

    valueAfterLastComma = (thisValue) => {
        return (thisValue && thisValue.length && thisValue.includes(",")) ? thisValue.substring(thisValue.lastIndexOf(",") + 1) : thisValue;
    }

    forwardToSettingsMandatory = () => {
        return (this.state.useGreylist)
            || (this.state.useWhitelist && !this.state.supplierInvoiceReceivingIgnoreSendersNotOnWhitelist);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.error !== this.props.error) {
            let error = this.props.error;
            this.setState({
                error: error
            });
        }

        // VAT rates, countries and currencies are checked in the reverse order to the priorities 
        // in which the warning messages about their absense should be displayed.
        // E.g. if neither currencies, countries or VAT rates are entered, the user should see the message that currencies are empty,
        // and only if some currencies are present, they should see the message that countries are empty, and so on.

        if (prevProps.companySettings !== this.props.companySettings) {
            if (this.props.companySettings) {
                this.setState({
                    useSupplierInvoiceReceiving: this.props.companySettings.useSupplierInvoiceReceiving,
                    supplierInvoiceReceivingHost: this.props.companySettings.supplierInvoiceReceivingHost,
                    supplierInvoiceReceivingUsername: this.props.companySettings.supplierInvoiceReceivingUsername,
                    hasSupplierInvoiceReceivingPassword: this.props.companySettings.hasSupplierInvoiceReceivingPassword,
                    supplierInvoiceReceivingUseStartTLS: this.props.companySettings.supplierInvoiceReceivingUseStartTLS,
                    supplierInvoiceReceivingImapPort: this.props.companySettings.supplierInvoiceReceivingImapPort,
                    supplierInvoiceReceivingUseAuth: this.props.companySettings.supplierInvoiceReceivingUseAuth,
                    supplierInvoiceReceivingSSLProtocols: this.props.companySettings.supplierInvoiceReceivingSSLProtocols,
                    supplierInvoiceReceivingSmtpPort: (this.props.companySettings.supplierInvoiceReceivingSmtpPort),
                    supplierInvoiceReceivingForwardTo: (this.props.companySettings.supplierInvoiceReceivingForwardTo || this.props.username),
                    supplierInvoiceReceivingSenderBlacklist: this.processCommaSeparatedList(this.props.companySettings.supplierInvoiceReceivingSenderBlacklist),
                    supplierInvoiceReceivingSenderGreylist: this.processCommaSeparatedList(this.props.companySettings.supplierInvoiceReceivingSenderGreylist),
                    supplierInvoiceReceivingSenderWhitelist: this.processCommaSeparatedList(this.props.companySettings.supplierInvoiceReceivingSenderWhitelist),
                    supplierInvoiceReceivingIgnoreSendersNotOnWhitelist: this.props.companySettings.supplierInvoiceReceivingIgnoreSendersNotOnWhitelist ? true : false,
                    supplierInvoiceReceivingAutoConvert: this.props.companySettings.supplierInvoiceReceivingAutoConvert,
                    supplierInvoiceReceivingAutoConvert_CustomerMatching: (this.props.companySettings.supplierInvoiceReceivingAutoConvert_CustomerMatching || "REQUIRE_TWO_FIELDS"),
                    supplierInvoiceReceivingAutoConvert_SupplierMatching: (this.props.companySettings.supplierInvoiceReceivingAutoConvert_SupplierMatching || "REQUIRE_TWO_FIELDS"),

                    useForwardTo: (this.props.companySettings.supplierInvoiceReceivingForwardTo
                        || (this.props.companySettings.supplierInvoiceReceivingSenderGreylist && this.props.companySettings.supplierInvoiceReceivingSenderGreylist.length)
                        || (this.props.companySettings.supplierInvoiceReceivingSenderWhitelist && this.props.companySettings.supplierInvoiceReceivingSenderWhitelist.length && !this.props.companySettings.supplierInvoiceReceivingIgnoreSendersNotOnWhitelist)),
                    useBlacklist: (this.props.companySettings.supplierInvoiceReceivingSenderBlacklist && this.props.companySettings.supplierInvoiceReceivingSenderBlacklist.length),
                    useGreylist: (this.props.companySettings.supplierInvoiceReceivingSenderGreylist && this.props.companySettings.supplierInvoiceReceivingSenderGreylist.length),
                    useWhitelist: (this.props.companySettings.supplierInvoiceReceivingSenderWhitelist && this.props.companySettings.supplierInvoiceReceivingSenderWhitelist.length),
                });
            }
        }

        if (prevState.supplierInvoiceReceivingSenderBlacklist !== this.state.supplierInvoiceReceivingSenderBlacklist) {
            this.setState({ blacklistLastEntry: "" });
        }
        if (prevState.supplierInvoiceReceivingSenderGreylist !== this.state.supplierInvoiceReceivingSenderGreylist) {
            this.setState({ greylistLastEntry: "" });
        }
        if (prevState.supplierInvoiceReceivingSenderWhitelist !== this.state.supplierInvoiceReceivingSenderWhitelist) {
            this.setState({ whitelistLastEntry: "" });
        }
        if (prevState.blacklistLastEntry !== this.state.blacklistLastEntry && this.state.blacklistLastEntry.includes(",")) {
            this.setState({
                supplierInvoiceReceivingSenderBlacklist: this.addValueBeforeLastComma(this.state.supplierInvoiceReceivingSenderBlacklist, this.state.blacklistLastEntry),
                blacklistLastEntry: this.valueAfterLastComma(this.state.blacklistLastEntry)
            })
        }
        if (prevState.greylistLastEntry !== this.state.greylistLastEntry && this.state.greylistLastEntry.includes(",")) {
            this.setState({
                supplierInvoiceReceivingSenderGreylist: this.addValueBeforeLastComma(this.state.supplierInvoiceReceivingSenderGreylist, this.state.greylistLastEntry),
                greylistLastEntry: this.valueAfterLastComma(this.state.greylistLastEntry)
            })
        }
        if (prevState.whitelistLastEntry !== this.state.whitelistLastEntry && this.state.whitelistLastEntry.includes(",")) {
            this.setState({
                supplierInvoiceReceivingSenderWhitelist: this.addValueBeforeLastComma(this.state.supplierInvoiceReceivingSenderWhitelist, this.state.whitelistLastEntry),
                whitelistLastEntry: this.valueAfterLastComma(this.state.whitelistLastEntry)
            })
        }
        if (prevState.useGreylist !== this.state.useGreylist
            || prevState.useWhitelist !== this.state.useWhitelist
            || prevState.supplierInvoiceReceivingIgnoreSendersNotOnWhitelist !== this.state.supplierInvoiceReceivingIgnoreSendersNotOnWhitelist
        ) {
            if (this.forwardToSettingsMandatory() && !this.state.useForwardTo) {
                this.setState({
                    useForwardTo: true
                });
            }
        }

        if ((!prevProps.saveSuccess && this.props.saveSuccess) || (prevState.error !== this.state.error && this.state.error)) {
            window.scrollBy(0, -document.body.scrollHeight);
        }
    }

    removeItemFromCommaSeparatedString = (thisItem, thisString) => {
        if (thisString === thisItem) {
            return "";
        } else {
            thisString = ("," + thisString + ",").replaceAll("," + thisItem + ",", ",");
            return thisString.substring(1, thisString.length - 1);
        }
    }

    removeItemFromStateField = (thisItem, stateField) => {
        this.setState({
            [stateField]: this.removeItemFromCommaSeparatedString(thisItem, this.state[stateField])
        });
    }

    render() {
        const pageTitle = "Supplier invoice processing settings | " + config.AppName;
        const breadcrumbsTitle = "Company settings";
        const breadcrumbsItem = "Supplier invoice processing";

        const loading = this.props.loadingCompanySettings;

        const supplierInvoiceReceivingControls =
            <React.Fragment>
                <h6 className="menu-title">Email server</h6>

                <div style={{ paddingLeft: "3rem" }}>
                    {editFormControls.textControl("supplierInvoiceReceivingHost", "Host", this.onChange, this.state.supplierInvoiceReceivingHost, "", null, !this.state.supplierInvoiceReceivingHost || !this.state.supplierInvoiceReceivingHost.length)}
                    {editFormControls.checkboxControl("supplierInvoiceReceivingUseAuth", "Authenticate", this.onChange, this.state.supplierInvoiceReceivingUseAuth)}
                    {this.state.supplierInvoiceReceivingUseAuth && editFormControls.textControl("supplierInvoiceReceivingUsername", "Username", this.onChange, this.state.supplierInvoiceReceivingUsername, "", null, !this.state.supplierInvoiceReceivingUsername || !this.state.supplierInvoiceReceivingUsername.length)}
                    {this.state.supplierInvoiceReceivingUseAuth && editFormControls.passwordControl("supplierInvoiceReceivingPassword", "Password", this.onChange, this.state.supplierInvoiceReceivingPassword, "", this.state.hasSupplierInvoiceReceivingPassword, this.state.changeSupplierInvoiceReceivingPassword, (thisValue) => this.setState({ changeSupplierInvoiceReceivingPassword: thisValue }))}
                    {editFormControls.numberControl("supplierInvoiceReceivingImapPort", "IMAP port", this.onChange, this.state.supplierInvoiceReceivingImapPort, 1, "Enter IMAP port", false, !this.state.supplierInvoiceReceivingImapPort)}
                    {editFormControls.checkboxControl("supplierInvoiceReceivingUseStartTLS", "Use StartTLS", this.onChange, this.state.supplierInvoiceReceivingUseStartTLS)}
                    {/* {editFormControls.staticTextControl("supplierInvoiceReceivingSSLProtocols", "SSL protocols", this.state.supplierInvoiceReceivingSSLProtocols)} */}
                </div>

                <h6 className="menu-title">Email processing rules</h6>

                <div style={{ paddingLeft: "3rem" }}>
                    {editFormControls.checkboxControl("useForwardTo", "Forward unrecognized or greylisted emails and attachments", this.onChange, this.state.useForwardTo,
                        'Highly recommended to have this setting ON in any case. Mandatory if greylist is used, or if whitelist is used without the setting "Ignore emails from all other addresses".',
                        this.forwardToSettingsMandatory())}
                    {this.state.useForwardTo && editFormControls.textControl("supplierInvoiceReceivingForwardTo", "Forward to email address:", this.onChange, this.state.supplierInvoiceReceivingForwardTo,
                        "Enter forward-to email address", null, this.state.useForwardTo && (!this.state.supplierInvoiceReceivingForwardTo || !this.state.supplierInvoiceReceivingForwardTo.length),
                        'Email address to which all unrecognized emails and attachments will be forwarded. This address will also be used for emails from greylisted addresses or emails outside of the whitelist, except if the setting "Ignore emails from all other addresses" is ON.')}
                    {this.state.useForwardTo && editFormControls.numberControl("supplierInvoiceReceivingSmtpPort", "SMTP port", this.onChange, this.state.supplierInvoiceReceivingSmtpPort, 1,
                        "Enter SMTP port", false, this.state.useForwardTo && !(this.state.supplierInvoiceReceivingSmtpPort),
                        "Other settings are shared with incoming emails")}
                    {editFormControls.checkboxControl("useBlacklist", "Use sender blacklist", this.onChange, this.state.useBlacklist, "Incoming email addresses which CashManager should ignore. This list takes precedence over greylist and whitelist, i.e. if an address is specified here, all emails from it will be ignored even if it is also specified in greylist or whitelist.")}
                    {this.state.useBlacklist && editFormControls.textAreaControlWithRemovableItems("blacklistLastEntry", "Sender blacklist", this.onChange,
                        this.state.blacklistLastEntry, this.state.supplierInvoiceReceivingSenderBlacklist, this.state.hoveredBlacklistItem,
                        (thisEntry) => this.removeItemFromStateField(thisEntry, "supplierInvoiceReceivingSenderBlacklist"),
                        (thisEntry) => this.setState({ hoveredBlacklistItem: thisEntry }),
                        () => this.setState({ hoveredBlacklistItem: null }),
                        "", "Specify incoming email addresses to be blacklisted, separated with commas. You can use wildcards ? (any character) and * (any character sequence).")}
                    {editFormControls.checkboxControl("useGreylist", "Use sender greylist", this.onChange, this.state.useGreylist, "Incoming email addresses which CashManager should forward instead of processing. This list takes precedence over whitelist, i.e. if an address is specified here, all emails from it will be forwarded even if it is also specified in whitelist.")}
                    {this.state.useGreylist && editFormControls.textAreaControlWithRemovableItems("greylistLastEntry", "Sender greylist", this.onChange,
                        this.state.greylistLastEntry, this.state.supplierInvoiceReceivingSenderGreylist, this.state.hoveredGreylistItem,
                        (thisEntry) => this.removeItemFromStateField(thisEntry, "supplierInvoiceReceivingSenderGreylist"),
                        (thisEntry) => this.setState({ hoveredGreylistItem: thisEntry }),
                        () => this.setState({ hoveredGreylistItem: null }),
                        "", "Specify incoming email addresses to be greylisted, separated with commas. You can use wildcards ? (any character) and * (any character sequence).")}
                    {editFormControls.checkboxControl("useWhitelist", "Use sender whitelist", this.onChange, this.state.useWhitelist, "Process only incoming emails from the specified addresses. If not checked, all incoming emails will be processed (unless the sender's address is blacklisted or greylisted).")}
                    {this.state.useWhitelist && editFormControls.textAreaControlWithRemovableItems("whitelistLastEntry", "Sender whitelist", this.onChange,
                        this.state.whitelistLastEntry, this.state.supplierInvoiceReceivingSenderWhitelist, this.state.hoveredWhitelistItem,
                        (thisEntry) => this.removeItemFromStateField(thisEntry, "supplierInvoiceReceivingSenderWhitelist"),
                        (thisEntry) => this.setState({ hoveredWhitelistItem: thisEntry }),
                        () => this.setState({ hoveredWhitelistItem: null }),
                        "", "Specify incoming email addresses to be whitelisted, separated with commas. You can use wildcards ? (any character) and * (any character sequence).")}
                    {this.state.useWhitelist && editFormControls.checkboxControl("supplierInvoiceReceivingIgnoreSendersNotOnWhitelist", "Ignore emails from all other addresses", this.onChange, this.state.supplierInvoiceReceivingIgnoreSendersNotOnWhitelist, "If checked, emails from any address not on the whitelist or the greylist will be ignored. If not checked, those emails will be forwarded instead.")}
                </div>

                <h6 className="menu-title">Supplier invoice processing</h6>

                <div style={{ paddingLeft: "3rem" }}>
                    {editFormControls.checkboxControl("supplierInvoiceReceivingAutoConvert", 'Automatically convert rows in "Ready" status to supplier invoices', this.onChange, this.state.supplierInvoiceReceivingAutoConvert, 'If checked, a received (unprocessed) invoice in the "Ready" status will be automatically converted to a supplier invoice. Invoices in the status "Data to update" and "Data errors" will not be converted.')}

                    <EditFormControlWithLabel fieldTitle="Customer matching" hintText="Determines how suppliers are matched against the data specified in the invoice">
                        {editFormControls.radioButton("supplierInvoiceReceivingAutoConvert_CustomerMatching", this.onChange, this.state.supplierInvoiceReceivingAutoConvert_CustomerMatching, "REQUIRE_REG_NO_OR_VAT_REG_NO", "By either registration No. or VAT registration No.")}
                        {editFormControls.radioButton("supplierInvoiceReceivingAutoConvert_CustomerMatching", this.onChange, this.state.supplierInvoiceReceivingAutoConvert_CustomerMatching, "REQUIRE_REG_NO", "Only by registration No.")}
                        {editFormControls.radioButton("supplierInvoiceReceivingAutoConvert_CustomerMatching", this.onChange, this.state.supplierInvoiceReceivingAutoConvert_CustomerMatching, "REQUIRE_VAT_REG_NO", "Only by VAT registration No.")}
                        {editFormControls.radioButton("supplierInvoiceReceivingAutoConvert_CustomerMatching", this.onChange, this.state.supplierInvoiceReceivingAutoConvert_CustomerMatching, "REQUIRE_TWO_FIELDS", "By any two fields")}
                        {editFormControls.radioButton("supplierInvoiceReceivingAutoConvert_CustomerMatching", this.onChange, this.state.supplierInvoiceReceivingAutoConvert_CustomerMatching, "REQUIRE_FULL_MATCH", "By all fields")}
                    </EditFormControlWithLabel>

                    <EditFormControlWithLabel fieldTitle="Supplier matching" hintText="Determines how suppliers are matched against the data specified in the invoice">
                        {editFormControls.radioButton("supplierInvoiceReceivingAutoConvert_SupplierMatching", this.onChange, this.state.supplierInvoiceReceivingAutoConvert_SupplierMatching, "REQUIRE_REG_NO_OR_VAT_REG_NO", "By either registration No. or VAT registration No.")}
                        {editFormControls.radioButton("supplierInvoiceReceivingAutoConvert_SupplierMatching", this.onChange, this.state.supplierInvoiceReceivingAutoConvert_SupplierMatching, "REQUIRE_REG_NO", "Only by registration No.")}
                        {editFormControls.radioButton("supplierInvoiceReceivingAutoConvert_SupplierMatching", this.onChange, this.state.supplierInvoiceReceivingAutoConvert_SupplierMatching, "REQUIRE_VAT_REG_NO", "Only by VAT registration No.")}
                        {editFormControls.radioButton("supplierInvoiceReceivingAutoConvert_SupplierMatching", this.onChange, this.state.supplierInvoiceReceivingAutoConvert_SupplierMatching, "REQUIRE_TWO_FIELDS", "By any two fields")}
                        {editFormControls.radioButton("supplierInvoiceReceivingAutoConvert_SupplierMatching", this.onChange, this.state.supplierInvoiceReceivingAutoConvert_SupplierMatching, "REQUIRE_FULL_MATCH", "By all fields")}
                    </EditFormControlWithLabel>

                    <br />

                    {editFormControls.mediumCheckboxControl("testEmailSettingsBeforeUpdating", "Test email server settings before updating", this.onChange, this.state.testEmailSettingsBeforeUpdating)}

                    <br />
                    {editFormControls.saveButton(this.props.saving, true)}
                </div>
            </React.Fragment>

        const editForm = (
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >
                                <Row>
                                    <FormGroup className="mb-4" row>
                                        <Col xs="auto">
                                            {editFormControls.checkboxControlWithoutLabel("useSupplierInvoiceReceiving", this.onChange, this.state.useSupplierInvoiceReceiving)}
                                        </Col>
                                        <Label
                                            htmlFor={"useSupplierInvoiceReceiving"}
                                            className={editFormControls.labelClass}
                                        >
                                            Process incoming supplier invoices
                                        </Label>
                                    </FormGroup>
                                </Row>

                                {this.state.useSupplierInvoiceReceiving && supplierInvoiceReceivingControls}
                                {!this.state.useSupplierInvoiceReceiving && editFormControls.saveButton(this.props.saving, true)}
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

                        {editFormControls.errorAlert(this.state.error)}

                        {editFormControls.warningAlertWithLink(this.state.warningText, this.state.warningLink, this.state.warningLinkText)}

                        {editFormControls.saveSuccessAlert(!this.state.changed && this.props.saveSuccess, "Settings")}

                        {editFormControls.formLoadingSpinner(loading)}

                        {!loading && editForm}

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ companySettings, userLogin }) => ({
    companySettings: companySettings.companySettings,
    error: companySettings.error,
    loadingCompanySettings: companySettings.loading,
    saveSuccess: companySettings.saveSuccess,
    saving: companySettings.saving,
    username: userLogin.userData.username
})

const mapDispatchToProps = dispatch => ({
    onGetCompanySettings: () => dispatch(actionsCompanySettings.companySettingsGet()),
    onUpdateCompanySettings: (companySettings) => dispatch(actionsCompanySettings.companySettingsUpdate_SupplierInvoiceProcessing(companySettings))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanySettingsEdit_SupplierInvoiceProcessing);
